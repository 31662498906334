import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import opensans400 from '@fontsource/open-sans/files/open-sans-latin-400-normal.woff2';
import opensans700 from '@fontsource/open-sans/files/open-sans-latin-700-normal.woff2';

// Configuration
import config from '../../../content/meta/config';

// Utilities
import { shorten } from '../../utils/helpers';

const Seo = ({
  title = config.siteTitle,
  description = config.siteDescription,
  slug = '',
  image = config.siteDefaultImage,
} = {
  title: config.siteTitle,
  description: config.siteDescription,
  slug: '',
  image: config.siteDefaultImage,
}) => {
  const language = config.siteLanguage;
  const url = slug
    ? `${config.siteUrl}${config.pathPrefix}${slug}`
    : `${config.siteUrl}${config.pathPrefix}`;

  if (title !== config.siteTitle) {
    title = `${title} | ${config.siteTitle}`;
  }

  if (description !== config.siteDescription) {
    description = shorten(description, 155);
  }

  if (image !== config.siteDefaultImage) {
    image = config.siteUrl + image;
  }

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: language,
          prefix: 'og: http://ogp.me/ns#',
        }}
      >
        <link rel="preload" as="font" href={opensans400} type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" as="font" href={opensans700} type="font/woff2" crossorigin="anonymous" />
        <script>
          {/* eslint-disable max-len */}
          {`!function(e){"use strict";function s(s){if(s){var t=e.documentElement;t.classList?t.classList.add("webp"):t.className+=" webp",window.sessionStorage.setItem("webpSupport",!0)}}!function(e){if(window.sessionStorage&&window.sessionStorage.getItem("webpSupport"))s(!0);else{var t=new Image;t.onload=t.onerror=function(){e(2===t.height)},t.src="data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA"}}(s)}(document);`}
        </script>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* Google verification */}
        {/* eslint-disable max-len */}
        <meta name="google-site-verification" content="LS6Ok227HqErdTI3_EMBzB9h42_sQ_6ADunUbQa7ZmU" />
        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {config.social.twitter &&
          <meta name="twitter:site" content={config.social.twitter} />
        }
        {config.social.twitter &&
          <meta name="twitter:creator:id" content={config.social.twitter} />
        }
      </Helmet>
    </div>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  image: PropTypes.string,
};

export default Seo;
