module.exports = {
  siteTitle: 'Sherpalo Ventures',
  siteLanguage: 'en',
  siteDescription: 'We guide and mentor exceptional entrepreneurs as they take their innovative ideas and disruptive technologies and turn them into successful businesses.',
  siteUrl: 'https://www.sherpalo.com',
  siteDefaultImage: 'https://www.sherpalo.com/images/sherpalo-logo_dark-bg.png',
  pathPrefix: '',
  // enablePosts: true,
  numPostsPerPage: 5,
  pathPrefixPosts: 'news',
  social: {
    twitter: '',
  },
  favicons: {
    android: false,
    appleIcon: true,
    appleStartup: true,
    coast: false,
    favicons: true,
    firefox: true,
    opengraph: false,
    twitter: false,
    yandex: false,
    windows: false,
  },
  ga: {
    trackingId: 'UA-224520-5',
    // Puts tracking script in the head instead of the body
    head: false,
    // Adds anonymize_ip flag when calling gtag.
    anonymize: true,
  },
};