export function shorten(str, maxLen, separator = ' ') {
  var cleanText = str.replace(/<\/?[^>]+(>|$)/g, ' ');
  if (cleanText.length <= maxLen) return cleanText;
  var strA = cleanText.substr(0, cleanText.lastIndexOf(separator, maxLen));
  var strB = strA.replace(/(\r\n\t|\n|\r\t)/gm,"");
  return strB + '\u2026';
}

export function stripProtocol(str) {
  return str.replace(/(^\w+:|^)\/\//, '');
}
